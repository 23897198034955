import { MantineStyleSystemProps } from "@mantine/core";
import { UseMutateFunction } from "@tanstack/react-query";
export interface Option {
  label: string;
  value: any;
}
export interface EditProps extends MantineStyleSystemProps {
  onChange?: (value: any) => void;
  object: any;
  prop: string;
  label?: string;
  className?: string;
  tooltip?: string;
  placeholder?: string;
  mutate: UseMutateFunction<any, unknown, any, unknown>;
  // eslint-disable-next-line unused-imports/no-unused-vars
  validate?(value: string): string | null;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
}
export const validateEmail = (val: string) => {
  if (val === "") return null;
  return /^\S+@\S+$/.test(val.trim()) ? null : "Invalid email";
};

import {
  Anchor,
  Button,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HttpError from "standard-http-error";
import { trackEvent } from "../../common/analytics";
import { login, register } from "../../common/api";
import { RegisterDto } from "../../common/auth/dto/register.dto";
import { i18Prefixes } from "../../common/i18";
import { useAuthStore } from "../../common/stores/auth.store";
import {
  MIN_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../common/users/user.schema";
import { MAX_NAME_STRING } from "../../common/util";
import { validateEmail } from "../inputs/util";
/* eslint-disable unused-imports/no-unused-vars */
export enum AuthState {
  login = "login",
  register = "register",
}
/* eslint-enable unused-imports/no-unused-vars */
export function AuthenticationForm({
  defaultState,
}: {
  defaultState?: AuthState;
}) {
  const { t } = useTranslation(["common", "auth"]);
  const router = useRouter();
  // router.query
  // console.log("router.query :", router.query);
  const token = useAuthStore((state) => state.token);
  const [defaultEmailSet, setDefaultEmailSet] = useState(false);
  const setToken = useAuthStore((state) => state.setToken);
  const setUser = useAuthStore((state) => state.setUser);
  // console.log("router.query.state :", router.query.state);
  const [state, setState] = useState<AuthState>(
    router.query.state === AuthState.register
      ? AuthState.register
      : defaultState || AuthState.login
  );
  // console.log("router.query :", router.query);
  const defaultEmail = router.query.email;
  // console.log("defaultEmail :", defaultEmail);
  const initialValues =
    process.env.NEXT_PUBLIC_PREFILL_AUTH === "1"
      ? {
          email: (process.env.NEXT_PUBLIC_PREFILL_AUTH_EMAIL as string) + "",
          password: process.env.NEXT_PUBLIC_PREFILL_AUTH_PASSWORD + "",
          name: process.env.NEXT_PUBLIC_PREFILL_AUTH_NAME + "" + Math.random(),
        }
      : {
          email: "",
          password: "",
          name: "",
        };
  const form = useForm({
    initialValues,
    validate: {
      email: validateEmail,
      password: (val) =>
        val.length < MIN_PASSWORD_LENGTH
          ? t("invalidPassword", i18Prefixes.auth)
          : null,
      name: (val) => {
        if (state === AuthState.register) {
          return val.trim().length < MIN_NAME_LENGTH
            ? t("invalidName", i18Prefixes.auth)
            : null;
        }
      },
    },
  });
  useEffect(() => {
    if (defaultEmail?.length && !defaultEmailSet) {
      form.setFieldValue("email", defaultEmail.toString());
      setDefaultEmailSet(true);
    }
  }, [defaultEmail, defaultEmailSet, form]);
  // const { errors } = form;
  const valid = form.isValid();
  const redirect = router.query.redirect?.toString() || "/dashboard";
  // console.log("valid :", valid);
  // console.log("errors :", errors);
  // const { values } = form;
  // console.log("values :", values);
  const {
    mutate: doLogin,
    error: loginError,
    isLoading: loginLoading,
  } = useMutation(login, {
    onSuccess(data) {
      setToken(data.token);
      setUser(data.user);
      trackEvent("login"); // do this in screen to have token in place
      // console.log("post login redirect to path :", path);
      router.replace(redirect);
    },
  });
  const {
    mutate: doRegister,
    error: registerError,
    isLoading: registerLoading,
  } = useMutation(register, {
    onSuccess(data) {
      setToken(data.token);
      setUser(data.user);
      trackEvent("sign_up"); // do this in screen to have token in place
      router.replace(redirect);
    },
  });
  const prepRegister = (values: RegisterDto) => {
    const dto = { ...values };
    if (dto.name.indexOf(" ") > 0) {
      const firstName = dto.name.split(" ")[0];
      dto.lastName = dto.name.split(" ").splice(1).join(" ");
      dto.name = firstName;
    }
    // console.log("dto :", dto);
    // return;
    doRegister(dto);
  };
  // console.log("loginLoading :", loginLoading);
  // console.log("error :", error);
  useEffect(() => {
    if (token) {
      // console.log("redirecting off login as token is set");
      router.replace("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const error = (loginError || registerError) as HttpError | undefined;
  useEffect(() => {
    if (!error) return;
    let message = error?.message;
    if (error.code === 400) message = "Invalid username or password";
    if (error.code === 409) message = "User already registered";
    if (error.code === 404) message = "No user found with that email";
    showNotification({
      message,
      autoClose: true,
      color: "red",
    });
  }, [error]);
  return (
    <Paper radius="md" p="xl" withBorder>
      <Text size="lg" weight={500} mb={10}>
        {state === AuthState.login
          ? t("loginPrompt", i18Prefixes.auth)
          : t("registerPrompt", i18Prefixes.auth)}
      </Text>
      <form
        onSubmit={form.onSubmit((values) => {
          // console.log("values :", values);
          // console.log("state :", state);
          state === AuthState.register ? prepRegister(values) : doLogin(values);
        })}
      >
        <Stack>
          {state === AuthState.register && (
            <TextInput
              label={t("name")}
              placeholder={t("namePlaceholder", i18Prefixes.auth)}
              value={form.values.name}
              className="name"
              maxLength={MAX_NAME_STRING}
              onChange={(event) =>
                form.setFieldValue("name", event.currentTarget.value)
              }
            />
          )}

          <TextInput
            required
            label={t("email")}
            placeholder={t("emailPlaceholder", i18Prefixes.auth)}
            value={form.values.email}
            className="email"
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
            error={form.errors.email && t("invalidEmail", i18Prefixes.auth)}
          />

          <PasswordInput
            required
            label={t("password")}
            placeholder={t("password")}
            value={form.values.password}
            className="password"
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            error={
              form.errors.password && t("invalidPassword", i18Prefixes.auth)
            }
          />

          {state === AuthState.register && (
            <Text size="xs">
              <Trans i18nKey="termsPrompt" ns="auth">
                By signing up you agree to the{" "}
                <Anchor component={Link} href="/terms">
                  Terms
                </Anchor>{" "}
                and{" "}
                <Anchor component={Link} href="/privacy">
                  Privacy
                </Anchor>{" "}
                policies
              </Trans>
            </Text>
          )}
        </Stack>

        <Group position="apart" mt="xl">
          <Anchor
            component="button"
            type="button"
            className="state-switch"
            onClick={() =>
              setState(
                state === AuthState.register
                  ? AuthState.login
                  : AuthState.register
              )
            }
            size="xs"
          >
            {state === AuthState.register
              ? t("loginLink", i18Prefixes.auth)
              : t("registerLink", i18Prefixes.auth)}
          </Anchor>
          <Anchor
            href="/forgot"
            component={Link}
            type="button"
            color="dimmed"
            size="xs"
          >
            {t("forgottenPassword", i18Prefixes.auth)}
          </Anchor>
          <Button
            type="submit"
            className="submit-auth"
            disabled={!valid}
            loading={loginLoading || registerLoading}
          >
            {state === AuthState.register ? t("register") : t("login")}
          </Button>
        </Group>
      </form>
    </Paper>
  );
}
